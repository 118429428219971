import {
  DiscountFormStateType,
  PaymentModeConcessionConfigType,
  CheckboxConfigType,
  SelectedDiscountType,
  DiscountBulkActionType,
  OfferValueType
} from '../types/discounts'
import { MerchantAttributesType } from '../types/merchantAttributes'
import { discountConstants, discountTableConstants } from './constants'
import { paymentModeMappings } from 'src/assets/DiscountsConfig'
import { getEnabledChannelsArray } from './parsers'
import { QuantityDiscountorBlockConfigType, CatalogueConfigType, CatalogueDataType } from '../types/discounts'
import { createDeepCopy } from './helpers'
import { includes } from 'lodash'

export const getUIConfig = (iterable: any, stateConfig: any) => {
  return iterable?.find((config: any) => config?.valueType === stateConfig?.valueType)
}

export const getPaymentModeConcessions = (discountStateObject: DiscountFormStateType | undefined) => {
  const payloadForPaymentModeConcessions: any = {}
  const paymentModes: string[] = []
  const offerValueConfig = discountStateObject?.setup?.applicationConfig?.offerValueConfig
  const isCodAccepted = offerValueConfig?.isCodAccepted
  const paymentModeConcessions = offerValueConfig?.paymentModeConcessions
  if (offerValueConfig?.paymentModeCoverageType === 'ALL' && offerValueConfig?.valueType === 'FREEBIE') {
    paymentModes.push('ALL')
  }
  if (offerValueConfig?.paymentModeCoverageType === 'SPECIFIC') {
    paymentModeConcessions?.forEach((mode: PaymentModeConcessionConfigType) => {
      if (Boolean(mode?.paymentModeCheckboxConfig?.valueType)) {
        if (offerValueConfig?.valueType === 'FREEBIE') {
          paymentModes.push(mode?.valueType)
        }

        payloadForPaymentModeConcessions[mode?.valueType] = {
          ...(offerValueConfig?.valueType === 'PERCENTAGE_CAPPED'
            ? { maximum_concession_amount: mode?.maxConcessionAmount }
            : {}),
          concession_amount: Boolean(mode?.concessionAmount) ? mode?.concessionAmount : 0
        }
      }
    })
  }

  return {
    payloadForPaymentModeConcessions,
    paymentModes
  }
}

export const getDefaultPaymentModeConcessions = () => {
  const defaultPaymentModeConcessions = paymentModeMappings?.map((paymentMode: any) => {
    return {
      paymentModeCheckboxConfig: { valueType: false },
      valueType: paymentMode?.valueType,
      concessionAmount: '',
      maxConcessionAmount: ''
    }
  })
  return defaultPaymentModeConcessions
}

export const parsePaymentModeConcessions = (data: any) => {
  if (!data) return getDefaultPaymentModeConcessions()
  let parsedPaymentModeConcessions: PaymentModeConcessionConfigType[] = []
  if (Array.isArray(data)) {
    parsedPaymentModeConcessions = data.map((paymentMode: string) => {
      return {
        paymentModeCheckboxConfig: { valueType: true },
        valueType: paymentMode,
        concessionAmount: '',
        maxConcessionAmount: ''
      }
    })
  }
  if (!Array.isArray(data)) {
    parsedPaymentModeConcessions = Object.keys(data).map((paymentMode: string) => {
      return {
        paymentModeCheckboxConfig: { valueType: true },
        valueType: paymentMode,
        concessionAmount: data[paymentMode]?.concession_amount ?? '',
        maxConcessionAmount: data[paymentMode]?.maximum_concession_amount ?? ''
      }
    })
  }
  const modifiedPaymentModeConcessionsState = getDefaultPaymentModeConcessions().map(
    (paymentMode: PaymentModeConcessionConfigType) => {
      return (
        parsedPaymentModeConcessions.find(
          (mode: PaymentModeConcessionConfigType) => mode.valueType === paymentMode.valueType
        ) ?? paymentMode
      )
    }
  )

  return modifiedPaymentModeConcessionsState
}

export const createDiscountPayload = (
  discountStateObject: DiscountFormStateType | undefined,
  merchant: MerchantAttributesType | undefined,
  isEditMode = false
) => {
  const { setup, conditions, customerEligibility, usageLimits, preview, isActive } = discountStateObject || {}
  const { applicationConfig } = setup || {}
  const { entitlementsConfig } = applicationConfig || {}

  const getLifeTimeOrderCount = (isMax = false) => {
    switch (customerEligibility?.valueType) {
      case 'ALL':
        return null
      case 'FIRST':
        return 0
      case 'CONDITIONAL':
        const maxOrderCount: number = parseFloat(customerEligibility?.maxOrderCount?.toString() ?? '0')
        const minOrderCount: number = parseFloat(customerEligibility?.minOrderCount?.toString() ?? '0')
        return isMax
          ? Boolean(customerEligibility?.maxOrderCountCheckboxConfig?.valueType)
            ? maxOrderCount
            : null
          : minOrderCount
    }
  }

  const getThresholdValue = () => {
    switch (setup?.applicationConfig?.valueType) {
      case 'BUNDLE':
        // In case of bundle discounts we are just sending prerequisite_count_to_entitlement
        //because everything that is included in the entitlements become prerequisite.
        return {
          prerequisite_subtotal_range: isEditMode ? -1 : null,
          prerequisite_count_to_entitlement: isEditMode ? -1 : null,
          prerequisite_max_order_value: isEditMode ? -1 : null,
          prerequisite_max_count_for_entitlement: isEditMode ? -1 : null
        }
      default:
        switch (conditions?.thresholdConfig?.valueType) {
          case 'AMOUNT':
            return {
              ...(conditions?.prerequisitesConfig?.valueType === 'ALL'
                ? {
                    prerequisite_max_count_for_entitlement: isEditMode ? -1 : null,
                    prerequisite_max_order_value: Boolean(conditions?.thresholdConfig?.maxCheckboxConfig?.valueType)
                      ? conditions?.thresholdConfig?.maxValue
                      : isEditMode
                      ? -1
                      : null,
                    prerequisite_count_to_entitlement: isEditMode ? -1 : null,
                    prerequisite_subtotal_range: conditions?.thresholdConfig?.minValue
                  }
                : {
                    prerequisite_max_count_for_entitlement: -1,
                    prerequisite_count_to_entitlement: -1,
                    prerequisite_max_order_value: -1,
                    prerequisite_subtotal_range: -1
                  })
            }
          case 'QUANTITY':
            let minQuantity = 0
            conditions?.prerequisitesConfig?.quantityDistributorConfig?.forEach(item => {
              if (item?.quantity) minQuantity += item?.quantity
            })
            return {
              ...(conditions?.prerequisitesConfig?.valueType === 'ALL'
                ? {
                    prerequisite_max_count_for_entitlement: Boolean(
                      conditions?.thresholdConfig?.maxCheckboxConfig?.valueType
                    )
                      ? conditions?.thresholdConfig?.maxValue
                      : isEditMode
                      ? -1
                      : null,
                    prerequisite_max_order_value: isEditMode ? -1 : null,
                    prerequisite_count_to_entitlement: conditions?.thresholdConfig?.minValue,
                    prerequisite_subtotal_range: isEditMode ? -1 : null
                  }
                : {
                    prerequisite_max_count_for_entitlement: -1,
                    prerequisite_count_to_entitlement: minQuantity,
                    prerequisite_max_order_value: -1,
                    prerequisite_subtotal_range: -1
                  })
            }
          case 'NONE':
            return {
              prerequisite_subtotal_range: isEditMode ? -1 : null,
              prerequisite_count_to_entitlement: isEditMode ? -1 : null,
              prerequisite_max_order_value: isEditMode ? -1 : null,
              prerequisite_max_count_for_entitlement: isEditMode ? -1 : null
            }
        }
    }
  }

  const createMetaData = () => {
    const coveredQuantityDistributorMetadata =
      setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.map(
        blockData => blockData?.itemsConfig?.catalogueMetadata
      )
    const prerequisiteQuantityDistributorMetadata = conditions?.prerequisitesConfig?.quantityDistributorConfig?.map(
      blockData => blockData?.itemsConfig?.catalogueMetadata
    )
    switch (setup?.applicationConfig?.valueType) {
      case 'AMOUNT':
        return {
          entitledCatalogue: [],
          ...(setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
            ? { coveredCatalogue: coveredQuantityDistributorMetadata }
            : { coveredCatalogue: [] }),

          ...(conditions?.thresholdConfig?.valueType !== 'NONE' && conditions?.prerequisitesConfig?.valueType !== 'ALL'
            ? { prerequisiteCatalogue: prerequisiteQuantityDistributorMetadata }
            : { prerequisiteCatalogue: [] })
        }
      case 'PREPAID':
        const prepaidQuantityDistributorMetadata =
          setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.map(
            blockData => blockData?.itemsConfig?.catalogueMetadata
          )
        return {
          ...(Boolean(setup?.applicationConfig?.offerValueConfig?.isCustomHeader) && {
            offerValueConfig: {
              isCustomHeader: setup?.applicationConfig?.offerValueConfig?.isCustomHeader
            }
          }),
          coveredCatalogue: [],
          ...(setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
            ? { entitledCatalogue: prepaidQuantityDistributorMetadata }
            : { entitledCatalogue: [] }),

          ...(conditions?.thresholdConfig?.valueType !== 'NONE' && conditions?.prerequisitesConfig?.valueType !== 'ALL'
            ? { prerequisiteCatalogue: prerequisiteQuantityDistributorMetadata }
            : { prerequisiteCatalogue: [] })
        }
      case 'BUNDLE':
        const bundleQuantityDistributorMetadata =
          setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.map(
            blockData => blockData?.itemsConfig?.catalogueMetadata
          )
        return {
          coveredCatalogue: [],
          prerequisiteCatalogue: [],

          ...(setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
            ? { entitledCatalogue: bundleQuantityDistributorMetadata }
            : { entitledCatalogue: [] })
        }
      case 'BXGY':
        const bxgyQuantityDistributorMetadata =
          setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.map(
            blockData => blockData?.itemsConfig?.catalogueMetadata
          )
        return {
          coveredCatalogue: [],

          ...(setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
            ? { entitledCatalogue: bxgyQuantityDistributorMetadata }
            : { entitledCatalogue: [] }),

          ...(conditions?.thresholdConfig?.valueType !== 'NONE' && conditions?.prerequisitesConfig?.valueType !== 'ALL'
            ? { prerequisiteCatalogue: prerequisiteQuantityDistributorMetadata }
            : { prerequisiteCatalogue: [] })
        }
      case 'CALLOUT_CARD':
        return {
          ...(conditions?.thresholdConfig?.valueType !== 'NONE' && conditions?.prerequisitesConfig?.valueType !== 'ALL'
            ? { prerequisiteCatalogue: prerequisiteQuantityDistributorMetadata }
            : { prerequisiteCatalogue: [] })
        }
    }
  }

  const getPayloadForQuantityDistributor = (catalogueCatagory: any, data: any) => {
    const isCollection = Boolean(catalogueCatagory === 'COLLECTION')
    const payload = data?.map((blockData: any) => {
      return {
        ...(Boolean(blockData?.quantity) ? { quantity: blockData?.quantity } : {}),
        ...(Boolean(blockData?.maxQuantity) && Boolean(blockData?.maxEnabled)
          ? { max_quantity: blockData?.maxQuantity }
          : {}),
        ...(Boolean(blockData?.price) ? { price: blockData?.price } : {}),
        ...(Boolean(blockData?.maxPrice) && Boolean(blockData?.maxEnabled) ? { max_price: blockData?.maxPrice } : {}),
        entities: [
          !isCollection &&
            Boolean(blockData?.itemsConfig?.items?.length) && {
              type: 'PRODUCT',
              entity_id: blockData?.itemsConfig?.items
            },
          Boolean(blockData?.itemsConfig?.variants?.length) &&
            !Boolean(blockData?.itemsConfig?.variants?.[0] === 'shopflo-all-products') && {
              type: 'VARIANT',
              entity_id: blockData?.itemsConfig?.variants
            },
          isCollection &&
            Boolean(blockData?.itemsConfig?.items?.length) &&
            !Boolean(blockData?.itemsConfig?.items?.[0] === 'shopflo-all-collections') && {
              type: 'COLLECTION',
              entity_id: isCollection ? blockData?.itemsConfig?.items : []
            }
        ].filter(Boolean)
      }
    })
    return payload
  }

  const getEntitledQuantityDistribution = () => {
    switch (setup?.applicationConfig?.valueType) {
      case 'AMOUNT':
        switch (setup?.applicationConfig?.entitlementsConfig?.valueType) {
          case 'ALL':
            return {
              entitled_entity_quantity_distribution: isEditMode ? [] : null
            }
          case discountConstants.CATALOGUE_PRODUCT:
            return {
              entitled_entity_quantity_distribution: getPayloadForQuantityDistributor(
                discountConstants.CATALOGUE_PRODUCT,
                setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig
              )
            }
          case discountConstants.CATALOGUE_COLLECTION:
            return {
              entitled_entity_quantity_distribution: getPayloadForQuantityDistributor(
                discountConstants.CATALOGUE_COLLECTION,
                setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig
              )
            }
        }
      case 'BUNDLE':
        switch (setup?.applicationConfig?.entitlementsConfig?.valueType) {
          case 'ALL':
            return {
              entitled_entity_quantity_distribution: isEditMode ? [] : null
            }
          default:
            return {
              entitled_entity_quantity_distribution: getPayloadForQuantityDistributor(
                setup?.applicationConfig?.entitlementsConfig?.valueType,
                setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig
              )
            }
        }
      case 'BXGY':
        return {
          entitled_entity_quantity_distribution: getPayloadForQuantityDistributor(
            setup?.applicationConfig?.entitlementsConfig?.valueType,
            setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig
          )
        }
      case 'PREPAID':
        switch (setup?.applicationConfig?.offerValueConfig?.valueType) {
          case 'FREEBIE':
            return {
              entitled_entity_quantity_distribution: getPayloadForQuantityDistributor(
                setup?.applicationConfig?.entitlementsConfig?.valueType,
                setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig
              )
            }
          default:
            return {
              entitled_entity_quantity_distribution: isEditMode ? [] : null
            }
        }
    }
  }

  const getPrerequisiteQuantityDistribution = () => {
    switch (conditions?.prerequisitesConfig?.valueType) {
      case 'ALL':
        return {
          prerequisite_entity_quantity_distribution: isEditMode ? [] : null
        }
      case discountConstants.CATALOGUE_PRODUCT:
        return {
          prerequisite_entity_quantity_distribution: getPayloadForQuantityDistributor(
            discountConstants.CATALOGUE_PRODUCT,
            conditions?.prerequisitesConfig?.quantityDistributorConfig
          )
        }
      case discountConstants.CATALOGUE_COLLECTION:
        return {
          prerequisite_entity_quantity_distribution: getPayloadForQuantityDistributor(
            discountConstants.CATALOGUE_COLLECTION,
            conditions?.prerequisitesConfig?.quantityDistributorConfig
          )
        }
    }
  }

  const getAggregatedCount = () => {
    let aggregatedQuantity = 0
    setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.forEach((blockData: any) => {
      aggregatedQuantity += blockData?.quantity
    })
    return aggregatedQuantity
  }

  const getStackConfig = () => {
    if (setup?.applicationConfig?.valueType === 'CALLOUT_CARD')
      return { stack_config: { stack_type: 'ALL', includes: ['*'] } }
    switch (usageLimits?.stackabilityType?.valueType) {
      case 'ALL':
        return { stack_config: { stack_type: 'ALL', includes: ['*'] } }
      case 'NONE':
        return { stack_config: { stack_type: 'NONE', includes: [] } }
      case 'CUSTOM':
        return {
          stack_config: {
            stack_type: 'CUSTOM',
            includes: usageLimits?.stackabilityType?.discountIDs?.filter(id => id !== '*')
          }
        }
    }
  }

  const getCoveredItemsCount = () => {
    const catalogueType = entitlementsConfig?.valueType
    let catalogueCount = 0
    let collectionCount = 0
    entitlementsConfig?.quantityDistributorConfig?.forEach((item: QuantityDiscountorBlockConfigType, index: number) => {
      if (!item?.itemsConfig?.catalogueMetadata?.length) return
      catalogueCount += item?.itemsConfig?.catalogueMetadata?.length
    })
    entitlementsConfig?.quantityDistributorConfig?.forEach((item: QuantityDiscountorBlockConfigType, index: number) => {
      if (!item?.itemsConfig?.items?.length) return
      collectionCount += item?.itemsConfig?.items?.length
    })
    return catalogueType === 'COLLECTION' ? collectionCount : catalogueCount
  }

  return {
    // (title/code) For bulk and automatic discounts send title and send code for everything else.
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType === 'BULK' ||
    setup?.applicationMethodConfig?.valueType === 'AUTOMATIC'
      ? { title: setup?.applicationMethodConfig?.value }
      : { code: setup?.applicationMethodConfig?.value, title: setup?.applicationMethodConfig?.value }),

    ...(setup?.applicationConfig?.valueType === 'CALLOUT_CARD' && {
      title: setup?.applicationConfig?.discountDescription?.discountTitle,
      header: setup?.applicationConfig?.discountDescription?.discountHeader,
      description: setup?.applicationConfig?.discountDescription?.discountDescription,
      long_description: setup?.applicationConfig?.discountDescription?.discountLongDescription?.replaceAll('•  ', '')
    }),
    ...(setup?.applicationConfig?.valueType === 'PREPAID' && {
      header:
        setup?.applicationConfig?.offerValueConfig?.isCustomHeader === 'CUSTOM'
          ? setup?.applicationConfig?.discountDescription?.discountHeader
          : ''
    }),
    ...(setup?.applicationConfig?.offerValueConfig?.valueType === 'FREEBIE' &&
      setup?.applicationConfig?.valueType !== 'PREPAID' && {
        header: `Get ${getCoveredItemsCount()} item for free`
      }),
    // tags (hardcoded tag for now "Shopflo")
    ...(!isEditMode ? { tags: [discountTableConstants.DISCOUNT_TAG_SHOPFLO] } : {}),

    //(amount_type) send "PERCENTAGE" in case of offerValue type "PERCENTAGE_CAPPED" and send offerValue type otherwise.
    amount_type:
      setup?.applicationConfig?.offerValueConfig?.valueType === 'PERCENTAGE_CAPPED' ||
      setup?.applicationConfig?.offerValueConfig?.valueType === 'FREEBIE'
        ? 'PERCENTAGE'
        : setup?.applicationConfig?.offerValueConfig?.valueType,

    //(concession_amount) Send consession_amount 0 if application valueType is "PREPAID" and
    //paymentModeCoverageType is "SPECIFIC" and send offerValue otherwise.
    ...(!Boolean(
      setup?.applicationConfig?.valueType === 'PREPAID' &&
        setup?.applicationConfig?.offerValueConfig?.paymentModeCoverageType === 'SPECIFIC'
    )
      ? { concession_amount: setup?.applicationConfig?.offerValueConfig?.value }
      : { concession_amount: 0 }),

    //(entitled_product_count) Send offerQuantity is case of BUNDLE and BXGY discounts
    entitled_product_count: Boolean(
      setup?.applicationConfig?.valueType === 'BXGY' ||
        (setup?.applicationConfig?.valueType === 'BUNDLE' &&
          setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL')
    )
      ? getAggregatedCount()
      : Boolean(
          setup?.applicationConfig?.valueType === 'PREPAID' &&
            setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
        )
      ? setup?.applicationConfig?.entitlementsConfig?.quantityDistributorConfig?.[0]?.itemsConfig?.items?.length
      : setup?.applicationConfig?.valueType === 'BUNDLE'
      ? setup?.applicationConfig?.offerValueConfig?.quantity
      : isEditMode
      ? -1
      : null,

    //(maximum_concession_amount) send cappedValue id offerValue Type is ("PERCENTAGE" and cap checkbox is enabled) or
    // (offerValue type is "PERCENTAGE_CAPPED")
    maximum_concession_amount: Boolean(
      (setup?.applicationConfig?.offerValueConfig?.capCheckboxConfig?.valueType &&
        setup?.applicationConfig?.offerValueConfig?.valueType === 'PERCENTAGE') ||
        setup?.applicationConfig?.offerValueConfig?.valueType === 'PERCENTAGE_CAPPED'
    )
      ? setup?.applicationConfig?.offerValueConfig?.cappedValue
      : isEditMode
      ? -1
      : null,

    // prerequisite keys
    ...getThresholdValue(),

    // coupon_type
    coupon_type: setup?.applicationConfig?.valueType,

    //created_by
    created_by: merchant?.attributes?.shopName,

    //(is_active) Send false is discount type is "PREPAID" as there can only be one prepaid discount active at once.
    is_active: setup?.applicationConfig?.valueType === 'PREPAID' && !isEditMode ? false : isActive,

    //(is_discoverable) Send false is case of automatic as we can not see automatic discounts in the discount listing.
    is_discoverable: setup?.applicationMethodConfig?.valueType === 'AUTOMATIC' ? false : preview?.isDiscoverable,

    //stack_config
    ...getStackConfig(),

    //is_auto_applicable
    is_auto_applicable: setup?.applicationMethodConfig?.valueType === 'AUTOMATIC',

    //start_date
    start_date:
      typeof usageLimits?.activePeriodConfig?.startDate === 'number'
        ? usageLimits?.activePeriodConfig?.startDate
        : usageLimits?.activePeriodConfig?.startDate?.getTime(),

    //end_date
    ...(Boolean(usageLimits?.activePeriodConfig?.endDataCheckboxConfig?.valueType)
      ? {
          end_date:
            typeof usageLimits?.activePeriodConfig?.endDate === 'number'
              ? usageLimits?.activePeriodConfig?.endDate
              : usageLimits?.activePeriodConfig?.endDate?.getTime()
        }
      : { end_date: isEditMode ? -1 : null }),

    //generation_source
    generation_source: setup?.applicationConfig?.discountGenerationConfig?.valueType,

    // bulk_generation keys (Send these keys if the discount generation valueType is BULK and bulkGeneration type is "RANDOM_CODES")
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType === 'BULK' &&
    setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.valueType === 'RANDOM_CODES'
      ? {
          number_of_coupons_to_create:
            setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig.codeCount,
          random_coupon_length: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.codeLength,
          prefix: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.prefix,
          suffix: setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.suffix,
          allowed_characters:
            setup?.applicationConfig?.discountGenerationConfig?.bulkGenerationConfig?.allowedCharacters,
          number_of_times_coupons_to_be_used: usageLimits?.discountUsesConfig?.maxUses
        }
      : {}),

    //(max_applicable_count) Send max_applicable_count if discountGeneration type is not "BULK" and if maxUses checkbox is checked
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType !== 'BULK'
      ? {
          ...(Boolean(usageLimits?.discountUsesConfig?.maxUsesCheckboxConfig?.valueType)
            ? { max_applicable_count: usageLimits?.discountUsesConfig?.maxUses }
            : { max_applicable_count: isEditMode ? -1 : null })
        }
      : {}),

    //(once_per_user) send it in case of "BULK" discount Generation type
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType === 'BULK' ? { once_per_user: true } : {}),

    //(multiple_application_options) Send if the discounttype is (BXGY and BUNDLE) and discountGeneration type is not "BULk"
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType !== 'BULK' &&
    Boolean(setup?.applicationConfig?.valueType !== 'PREPAID')
      ? {
          multiple_application_options: {
            enable: Boolean(setup?.applicationConfig?.maxUsesPerOrderCheckboxConfig?.valueType),
            number_of_times_to_apply: Boolean(setup?.applicationConfig?.maxUsesPerOrder)
              ? setup?.applicationConfig?.maxUsesPerOrder
              : null
          }
        }
      : {}),

    //(max_applicable_per_user_count) Send if discountGenerationType is not BULK and maxUsesPerCustomer checkbox is checked
    ...(setup?.applicationConfig?.discountGenerationConfig?.valueType !== 'BULK'
      ? {
          max_applicable_per_user_count: usageLimits?.discountUsesConfig?.maxUsesPerCustomerCheckboxConfig?.valueType
            ? usageLimits?.discountUsesConfig?.maxUsesPerCustomer
            : isEditMode
            ? -1
            : null
        }
      : {}),

    //prerequisite_user_order_cohort
    prerequisite_user_order_cohort: {
      lifetime_max_order_count: getLifeTimeOrderCount(true),
      lifetime_min_order_count: getLifeTimeOrderCount()
    },

    //prerequisite_customer_segments
    ...(customerEligibility?.valueType === 'SEGMENTS' && Boolean(customerEligibility?.segmentIds?.length)
      ? { segment_ids: customerEligibility?.segmentIds, override_pre_evaluated_segment: true }
      : {}),

    //prerequisite_customer_ids
    ...(customerEligibility?.valueType !== 'SPECIFIC'
      ? { prerequisite_customer_ids: [], customer_specific: false }
      : { customer_specific: true }),

    //prerequisite_entity_quantity_distribution
    ...getPrerequisiteQuantityDistribution(),
    ...(Boolean(conditions?.prerequisitesConfig?.hasOldProductConstruct)
      ? { prerequisite_product_ids: [], prerequisite_collection_ids: [], prerequisite_variant_ids: [] }
      : {}),

    //application_target
    application_target: Boolean(
      setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL' ||
        setup?.applicationConfig?.entitlementsConfig?.valueType !== 'ALL'
    )
      ? 'ENTITLED'
      : 'ALL',

    //entitled_entity_quantity_distribution
    ...getEntitledQuantityDistribution(),
    ...(Boolean(setup?.applicationConfig?.entitlementsConfig?.hasOldProductConstruct)
      ? { entitled_product_ids: [], entitled_collection_ids: [], entitled_variant_ids: [] }
      : {}),

    //bxgy_product_strategy (Needs to be checked once)
    bxgy_product_strategy:
      setup?.applicationConfig?.valueType === 'BXGY' ||
      setup?.applicationConfig?.offerValueConfig?.valueType === 'FREEBIE'
        ? 'ADD_PRODUCT'
        : 'DISCOUNT_ON_PRODUCT',

    //metadata
    metadata: createMetaData(),

    //List of Active Channels
    enabled_channels: getEnabledChannelsArray(discountStateObject?.channels)
  }
}

export const getQuantityDistributerDefaultConfig = (
  haveMultipleGroups: boolean,
  blockData?: QuantityDiscountorBlockConfigType[]
) => {
  const parsedItemsConfig: CatalogueConfigType = {
    valueType: 'PRODUCT_AND_VARIANT',
    variants: [],
    items: [],
    catalogueMetadata: []
  }
  blockData?.forEach((item: QuantityDiscountorBlockConfigType) => {
    const { itemsConfig } = item
    parsedItemsConfig.valueType = itemsConfig?.valueType
    itemsConfig?.variants?.forEach((variant: string | undefined) => {
      parsedItemsConfig?.variants?.push(variant)
    })
    itemsConfig?.items?.forEach((item: string | undefined) => {
      parsedItemsConfig?.items?.push(item)
    })
    itemsConfig?.catalogueMetadata?.forEach((metadata: CatalogueDataType) => {
      const extractedMetadata = createDeepCopy<CatalogueDataType>(metadata) as CatalogueDataType
      parsedItemsConfig?.catalogueMetadata?.push(extractedMetadata)
    })
  })

  if (haveMultipleGroups) {
    return [{ quantity: 1, itemsConfig: parsedItemsConfig }]
  }
  return [{ quantity: null, itemsConfig: parsedItemsConfig }]
}

export const generateBulkPayload = (selectedDiscounts: SelectedDiscountType[], operation: DiscountBulkActionType) => {
  switch (operation.type) {
    case 'TOGGLE_STATUS':
      const updatedItems: { [key: string]: boolean } = {}
      selectedDiscounts
        .filter(discount =>
          operation.action === 'ACTIVATE'
            ? !discount.isActive || discount.scheduled || discount.expired
            : discount.isActive && !discount.scheduled && !discount.expired
        )
        .forEach(discount => (updatedItems[discount.discountId] = operation.action === 'ACTIVATE'))
      return {
        items_to_update: updatedItems,
        action: operation.type
      }
  }
}

export const getDefaultHeaderForPaymentOffer = (valueType: OfferValueType) => {
  switch (valueType) {
    case 'PERCENTAGE':
      return 'Extra <value>% off'
    case 'PERCENTAGE_CAPPED':
      return 'Get ₹<value> off'
    case 'FLAT':
      return 'Get ₹<value> off'
    case 'FREEBIE':
      return 'Get items worth ₹<value>'
  }
}
