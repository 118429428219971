import React, { createContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { constants } from '../utils/constants'
import { useRouter } from 'next/router'
import { hasAuthCookie, redirectToLogin } from '../utils/helpers'
import { setAuthCookies } from '../utils/auth'

interface AuthContextType {
  login: (
    idToken: string,
    email: string,
    name: string,
    refreshToken: string,
    authExpiry?: number,
    refreshExpiry?: number
  ) => void
  loggedIn: boolean
  logout: () => void
  showAnnouncement: boolean
  hideAnnouncement: () => void
}

const AuthContext = createContext({} as AuthContextType)

const AuthProvider = (props: any) => {
  const [loggedIn, setLoggedIn] = useState(hasAuthCookie())
  const [showAnnouncement, setShowAnnouncement] = useState(false)
  const router = useRouter()

  // uncomment this to show the announcement bar on every login
  // useEffect(() => {
  //   const parsedShowAnnouncement = JSON.parse(Cookies.get(constants.FLO_SHOW_ANNOUNCEMENT) || 'false')
  //   if (parsedShowAnnouncement) {
  //     setShowAnnouncement(true)
  //   }
  // }, [])

  const login = (
    idToken: string,
    email: string,
    name: string,
    refreshToken: string,
    authExpiry?: number,
    refreshExpiry?: number
  ) => {
    setAuthCookies(idToken, refreshToken, authExpiry, refreshExpiry)

    //Setting user data in localstorage
    Cookies.set(
      constants.FLO_USER_DATE,
      JSON.stringify({
        email: email,
        name: name
      }),
      {
        expires: constants.FLO_USER_COOKIE_EXPIRY,
        sameSite: 'none',
        secure: true
      }
    )
    setLoggedIn(true)
    // uncomment this to show the announcement bar on every login
    // Cookies.set(constants.FLO_SHOW_ANNOUNCEMENT, 'true')
    // setShowAnnouncement(true)
  }

  const logout = () => {
    setLoggedIn(false)
    redirectToLogin()
  }

  const hideAnnouncement = () => {
    Cookies.set(constants.FLO_SHOW_ANNOUNCEMENT, 'false')
    setShowAnnouncement(false)
  }

  const authContextValue: AuthContextType = {
    login,
    loggedIn,
    logout,
    showAnnouncement,
    hideAnnouncement
  }

  return <AuthContext.Provider value={authContextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
