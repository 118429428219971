import React, { createContext, useEffect, useState } from 'react'
import { IntegrationsType } from '../types/integrations'
import { parseIntegrationsConfig } from '../utils/parsers'

interface IntegrationsContextType {
  setIntegrations: (integrationsObject: IntegrationsType, useParsing?: boolean) => void
  integrations: IntegrationsType
}

const IntegrationsContext = createContext({} as IntegrationsContextType)

const initialState = {
  google: {
    enabled: false
  },
  ga: {
    enabled: false,
    measurementId: '',
    apiSecret: ''
  },
  facebook: {
    enabled: false,
    pixels: []
  },
  webengage: {
    enabled: false,
    licenseCode: '',
    apiKey: '',
    isIndiaDatacenter: true
  },
  googleAds: {
    enabled: false,
    tags: []
  },
  contlo: {
    apiKey: '',
    enabled: false
  },
  orderConfirmation: {
    enabled: false,
    script: ''
  },
  clevertap: {
    enabled: true,
    accountId: '',
    passcode: '',
    region: '',
    identity: ''
  },
  moengage: {
    enabled: false,
    appId: '',
    dataCenter: '',
    customerIdType: '',
    dataAPIId: '',
    dataAPIKey: ''
  },
  webhooks: {
    enabled: false,
    webhooks: []
  },
  limechat: {
    enabled: false,
    limechat: []
  },
  shipRocket: {
    enabled: false,
    provider: 'SHIP_ROCKET',
    email: '',
    password: '',
    pickup_pincode: '',
    shipRocketUIFields: []
  },
  clickPost: {
    enabled: false,
    provider: 'CLICKPOST',
    username: '',
    key: '',
    pickup_pincode: '',
    clickPostUIFields: []
  },
  popCoins: {
    loyaltyProvider: '',
    metadata: {
      authToken: ''
    },
    isActive: false
  }
}

const IntegrationsProvider = (props: any) => {
  const [integrations, setIntegrationsData] = useState<IntegrationsType>(initialState)

  const setIntegrations = (integrationsObject: IntegrationsType, useParsing = true) => {
    setIntegrationsData(useParsing ? parseIntegrationsConfig(integrationsObject) : integrationsObject)
  }

  const IntegrationsContextValue: IntegrationsContextType = {
    setIntegrations,
    integrations
  }

  return <IntegrationsContext.Provider value={IntegrationsContextValue} {...props} />
}

const useIntegrations = () => React.useContext(IntegrationsContext)

export { IntegrationsProvider, useIntegrations }
