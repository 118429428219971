import React, { createContext, useState } from "react";
import { FilterConfigType, TabType } from "../types/filters";
import { constants } from "../utils/constants";
import { getDateTime, getFiltersFromLocalStorage, setFiltersInLocalStorage } from "../utils/helpers";

interface FilterContextType {
  setFiltersCtx: () => void
  setOrdersTableFiltersCtx: (filterObject: FilterConfigType) => void
  ordersTableFilters: FilterConfigType
  setAnalyticsFiltersCtx: (filterObject: FilterConfigType) => void
  analyticsFilters: FilterConfigType
  setAbandonedCartTableFiltersCtx: (filterObject: FilterConfigType) => void
  abandonedCartTableFilters: FilterConfigType
}

const FilterContext = createContext({} as FilterContextType);

const ordersInitialState: FilterConfigType = { 
  selectedStaticRange: "today", 
  searchParam: 'email',
  selectedCompareStaticRange: '7Days'
}

const analyticsInitialState: FilterConfigType = {
  selectedStaticRange: "today",
  comparison: "none",
  selectedCompareStaticRange: '7Days',
  lastUpdated: `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
}

const abandonedCartsInitialState: FilterConfigType = { 
  selectedStaticRange: "today",
  searchParam: 'email',
  selectedCompareStaticRange: '7Days'
}


const getInitialState = (tab: TabType): any => {
  switch(tab) {
    case "orders":
      return getFiltersFromLocalStorage(tab) ?? ordersInitialState
    case "analytics":
      return getFiltersFromLocalStorage(tab) ?? analyticsInitialState
    case "abandonedCarts": 
      return getFiltersFromLocalStorage(tab) ?? abandonedCartsInitialState
  }
}


const FilterProvider = (props: any) => {
  const [ordersTableFilters, setOrdersTableFilters] = useState<FilterConfigType>(getInitialState('orders'))
  const [analyticsFilters, setAnalyticsFilters] = useState<FilterConfigType>(getInitialState('analytics'))
  const [abandonedCartTableFilters, setAbandonedCartTableFilters] = useState<FilterConfigType>(
    getInitialState('abandonedCarts')
  )

  // Set Filters in localStorage when loggedIn
  const setFiltersCtx = () => {
    const filters = globalThis?.window?.localStorage[constants.FLO_FILTERS]
    const parsedFilters = typeof filters !== 'undefined' && JSON.parse(filters)
    if (
      !filters ||
      !Boolean(parsedFilters?.orders?.selectedStaticRange) ||
      !Boolean(parsedFilters?.analytics?.selectedStaticRange) ||
      !Boolean(parsedFilters?.abandonedCarts?.selectedStaticRange) ||
      !Boolean(parsedFilters?.analytics?.lastUpdated) ||
      !Boolean(parsedFilters?.cart_analytics?.selectedStaticRange)
    ) {
      globalThis?.window?.localStorage.setItem(
        constants.FLO_FILTERS,
        JSON.stringify({
          orders: {
            selectedStaticRange: 'today',
            searchParam: 'email'
          },
          analytics: {
            selectedStaticRange: 'today',
            comparison: 'none',
            lastUpdated: `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
          },
          abandonedCarts: {
            selectedStaticRange: 'today',
            searchParam: 'email'
          },
          rewards: {
            selectedStaticRange: 'today',
            selectedCompareStaticRange: 'thisWeek',
            comparison: 'none',
            lastUpdated: `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
          },
          upsell: {
            selectedStaticRange: 'today',
            selectedCompareStaticRange: 'thisWeek',
            comparison: 'none',
            lastUpdated: `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
          },
          cart_analytics: {
            selectedStaticRange: 'today',
            selectedCompareStaticRange: 'thisWeek',
            comparison: 'none',
            lastUpdated: `${getDateTime(new Date()).date} at ${getDateTime(new Date()).time}`
          },
          insights: {
            selectedStaticRange: '30Days',
          }
        })
      )
      setOrdersTableFilters(getInitialState('orders'))
      setAnalyticsFilters(getInitialState('analytics'))
      setAbandonedCartTableFilters(getInitialState('abandonedCarts'))
    }
  }

  // Context Setters
  const setOrdersTableFiltersCtx = (filterObject: FilterConfigType) => {
    setOrdersTableFilters(filterObject)
    setFiltersInLocalStorage("orders", filterObject)
  };

  const setAnalyticsFiltersCtx = (filterObject: FilterConfigType) => {
    setAnalyticsFilters(filterObject)
    setFiltersInLocalStorage("analytics", filterObject)
  };

  const setAbandonedCartTableFiltersCtx = (filterObject: FilterConfigType) => {
    setAbandonedCartTableFilters(filterObject)
    setFiltersInLocalStorage('abandonedCarts', filterObject)
  }


  // Context Values
  const filterContextValue: FilterContextType = {
    setFiltersCtx,
    setOrdersTableFiltersCtx,
    ordersTableFilters,
    setAnalyticsFiltersCtx,
    analyticsFilters,
    setAbandonedCartTableFiltersCtx,
    abandonedCartTableFilters
  }

  return <FilterContext.Provider value={filterContextValue} {...props} />;
};

const useFilter = () => React.useContext(FilterContext);

export { FilterProvider, useFilter };