import { BannerConfigType } from './customizeUI'
import { CustomisablePageType, ThemeColorPaletteType } from './merchantAttributes'

export type customizeCartUIData = {
  cartBannerUIConfigData: BannerConfigType
  cartBottomBannerUIConfigData: BannerConfigType
  colorPaletteData: ThemeColorPaletteType
  cartButtonCTAText: string
  cartWidthForMobile: CART_WIDTH_FOR_MOBILE
}

export type CartButtonCTAText = {
  type: CustomisablePageType
  CTAText: string
}

export type BannerConfigTypeForBackend = {
  enabled: boolean
  background_color: string
  html_text_string: string
  type: CustomisablePageType
  text_color: string
}

export type PrimaryCTATextTypeForBackend = {
  type: CustomisablePageType
  cta_text: string
}

export enum CART_WIDTH_FOR_MOBILE {
  FULL = "full",
  WINDOWED = "windowed"
}
