import { FlaskEmpty } from 'mdi-material-ui'
import { CatalogueDataType } from '../types/discounts'
import { VariantDetailType } from '../types/orders'
import { analyticsConstants, discountConstants, discountTableConstants, orderTableConstants } from './constants'
import { getDateTime, getSelectionStatus, numberWithCommas, roundToDecimals, truncateOnOverflow } from './helpers'
import { UpsellEntitledProductType, UpSellTableDataType } from '../types/upsell'
import { getOfferValueAmountType } from './parsers'

export const OrdersTableAdapter = (
  data: any,
  created_at: string,
  source_order_id: string,
  source_order: any,
  fms_order: any,
  isEdited: boolean,
  customerName: string,
  email: string,
  phone: string
) => {
  const { customer, shipping_address, billing_address } = data ?? {}
  return {
    orderId: {
      name: data?.name,
      orderStatusUrl: data?.order_status_url
    },
    dateCreated: created_at,
    customerName: customerName,
    email: email,
    phone: phone,
    paymentMode:
      fms_order?.metadata?.payment_context === 'SPLIT_COD'
        ? orderTableConstants.PAYMENT_MODE_SPLIT_COD
        : source_order?.order_type === 'COD'
        ? orderTableConstants.PAYMENT_MODE_COD
        : orderTableConstants.PAYMENT_MODE_PREPAID,
    totalPrice: source_order?.amount,
    paymentStatus: data?.financial_status,
    refunds: fms_order?.status === 'REFUNDED' ? orderTableConstants.REFUND_STATUS_REFUNDED : '',
    refundId: fms_order?.refunds?.[0]?.pg_reference_id ?? '',
    cancellationStatus: source_order?.status === 'CANCELLED' ? orderTableConstants.CANCELLATION_STATUS_CANCELLED : '',
    sourceOrderId: source_order_id,
    checkoutId: source_order?.checkout_id,
    isEdited: isEdited ?? false,
    tags: data?.tags ?? ''
  }
}

/** Maps response data to the abandoned carts table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const AbandonedCartAdapter = (row: any) => {
  const pricing = row?.pricing
  return {
    id: row?.uid,
    dateCreated: row?.created_at,
    placedBy: row?.customer_name,
    dropOffEvent: row?.dropped_off_event,
    phone: row?.phone_no,
    status: row?.status,
    orderValue: pricing?.total_payable,
    marketingConsent: row.marketing_consent
  }
}

export const AbandonedCartTableDrawerAdapter = (data: any) => {
  const {
    line_items,
    checkout_url,
    drop_off_event,
    customer,
    shipping_address,
    invoices,
    created_at,
    status,
    note_attribute
  } = data || {}

  const getUTMDetails = (data: any) => {
    const utmDataArray = data
      ?.filter((filterData: any) => filterData?.name !== 'GSTIN' || filterData?.name !== 'GSTINBusinessName')
      ?.map((utmData: any) => {
        return {
          label: utmData?.name,
          value: Boolean(utmData?.value?.length) ? utmData?.value : orderTableConstants.DASH
        }
      })
    return utmDataArray
  }

  const varaintDetails = line_items?.map((item: any, index: number) => {
    return {
      id: item?.id,
      itemID: item?.item_id,
      itemName: item?.product_title ?? '',
      itemPrice: item?.price,
      itemQuantity: item?.quantity,
      originalQty: item?.quantity,
      imageURL: item?.image,
      itemVariantName: item?.variant_title ?? '',
      productId: item?.product_id
    }
  })

  const address = [
    {
      isEmpty: shipping_address?.length === 0,
      name: shipping_address?.name ?? '',
      addressLine1: shipping_address?.address1 ?? '',
      addressLine2: shipping_address?.address2 ?? '',
      city: shipping_address?.city ?? '',
      country: shipping_address?.country ?? '',
      state: shipping_address?.state ?? '',
      state_code: shipping_address?.state_code ?? '',
      pincode: shipping_address?.zip ?? '',
      country_code: shipping_address?.country_code ?? '',
      email: shipping_address?.email ?? '',
      phone: shipping_address?.phone ?? '',
      type: shipping_address?.type ?? ''
    }
  ]

  const parsedDiscountData = invoices?.discounts?.map((item: any, index: number) => {
    return {
      code: item?.coupon_metadata?.title ?? item?.discount_code,
      amount: `- \u20B9${numberWithCommas(item?.discount)}`,
      label: item?.source
    }
  })

  return {
    dateCreated: `${getDateTime(new Date(created_at))?.date} at ${getDateTime(new Date(created_at))?.time}`,
    customerName: customer?.name ?? orderTableConstants.DASH,
    customerPhone: customer?.phone ?? orderTableConstants.DASH,
    customerEmail: customer?.email ?? orderTableConstants.DASH,
    status: status ?? orderTableConstants.DASH,
    checkoutUrl: checkout_url ?? orderTableConstants.DASH,
    dropOffEvent: drop_off_event ?? orderTableConstants.DASH,
    variantDetails: varaintDetails ?? orderTableConstants.NOT_APPLICABLE,
    address: address,
    subtotal: `\u20B9${numberWithCommas(invoices?.sub_total)}`,
    discounts: parsedDiscountData ?? orderTableConstants.DASH,
    shipping: `\u20B9${numberWithCommas(invoices?.shipping < 0 ? 0 : invoices?.shipping)}`,
    taxes: `\u20B9${numberWithCommas(invoices?.tax)}`,
    totalPrice: `\u20B9${numberWithCommas(invoices?.total_payable)}`,
    rewards: `- \u20B9${numberWithCommas(invoices?.rewards)}`,
    utmDetails: getUTMDetails(note_attribute),
    codCharges: `+ \u20B9${numberWithCommas(invoices?.cod ?? 0)}`
  }
}

/** Maps response data to the discounts table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const discountsAdapter = (row: any, order: any) => {
  const getCouponStatus = () => {
    if (Boolean(row?.end_date) && row?.end_date < Date.now()) return 'Expired'
    if (Boolean(row?.active)) return Boolean(row?.start_date) && row?.start_date > Date.now() ? 'Scheduled' : 'Active'
    return 'Inactive'
  }
  const getDiscountRuleSummary = () => {
    return 'Something good is here.'
  }
  return {
    id: row?.code,
    code: row?.auto_applicable ? row?.title ?? row?.code ?? '' : row?.code ?? '',
    combines:
      (row?.stackable || row?.stack_config?.stack_type === 'ALL' || row?.stack_config?.stack_type === 'CUSTOM') ??
      false,
    automatic: row?.auto_applicable ?? false,
    imported: Boolean(row['tags']) && Boolean(row['tags']?.find((element: string) => element === 'SHOPIFY')),
    discountRuleSummary: getDiscountRuleSummary(),
    expires: row?.end_date,
    status: getCouponStatus(),
    isActive: row?.active ?? false,
    couponId: row?.coupon_id,
    startDate: row?.start_date,
    description: row?.description ?? '',
    discoverable: row?.discoverable ?? false,
    longDescription: row?.long_description ?? '',
    descriptionTitle: row?.header ?? '',
    title: row?.title ?? '',
    weight: row?.discoverable ? order : null,
    usage: row?.usage ?? 0,
    gmv: row?.gmv ?? 0,
    burn: row?.burn ?? 0,
    sharableLink: row?.sharable_link,
    prepaid: Boolean(row?.coupon_type === 'PREPAID'),
    prepaidType: getOfferValueAmountType(row),
    synced: row?.tags?.includes('PLATFORM'),
    aiGenerated: row?.tags?.includes('AI_GENERATED')
  }
}

/** Maps response data to the discounts sets table field data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const DiscountSetsApdater = (row: any) => {
  return {
    jobId: row?.job_id,
    completionPercentage: row?.completion_percentage,
    title: row?.title,
    status: row?.state,
    couponId: row?.associated_discount_code,
    couponCount: row?.total_coupons_created
  }
}

/** Maps response data to the catalogue data
 * @param {any} row                 Response Data to be parsed
 * @return                          Parsed Object
 */
export const catalogueAdapter = (row: any, catalogueType: string | undefined) => {
  const getVariants = () => {
    const variantData = row?.variants?.map((variant: any) => {
      const adaptedVariant = catalogueAdapter(variant, 'VARIANT')
      return adaptedVariant
    })
    return variantData
  }

  switch (catalogueType) {
    case discountConstants.CATALOGUE_COLLECTION:
      return {
        id: row?.collection_id,
        imageUrl: row?.collection_image?.src,
        description: row?.name,
        isVariant: false
      }
    case discountConstants.CATALOGUE_PRODUCT:
      const primaryImage = row?.images?.find((image: any) => image?.is_primary)?.src
      return {
        id: row?.product_id,
        imageUrl: primaryImage ?? row?.images?.[0]?.src,
        description: row?.name,
        isVariant: false,
        variants: getVariants(),
        totalVariantCount: row?.variants?.length ?? 0
      }
    case discountConstants.CATALOGUE_VARIANT:
      return {
        id: row?.variant_id,
        price: row?.current_price,
        inventory: row?.inventory_quantity ?? 0,
        description: row?.name,
        isVariant: true,
        parentDetails: {
          id: row?.product?.product_id,
          name: row?.product?.description,
          imageUrl: row?.product?.images?.[0]?.src
        }
      }
  }
}

const mapGateWay = (pgType: string) => {
  switch (pgType) {
    case 'SF_CF':
      return 'Cashfree'
    case 'CF':
      return 'Cashfree'
    case 'SF_RP':
      return 'Razorpay'
    case 'RP':
      return 'Razorpay'
    case 'PHONEPE':
      return 'PhonePe'
    case 'EASEBUZZ':
      return 'Easebuzz'
    case 'PAYU':
      return 'PayU'
    case 'PAYTM':
      return 'Paytm'
    case 'CCAVENUE':
      return 'CCAvenue'
    case 'BHARATX':
      return 'BharatX'
    case 'SF_SNPM':
      return 'Snapmint'
    case 'SNPM':
      return 'Snapmint'
    case 'SIMPL':
      return "Simpl"
    default:
      return orderTableConstants.DASH
  }
}

const mapPaymentMode = (mode: string, status?: string) => {
  switch (mode) {
    case 'WALLET':
      return 'Wallet'
    case 'UPI_INTENT':
      return 'UPI'
    case 'UPI_COLLECT':
      return 'UPI'
    case 'UPI':
      return 'UPI'
    case 'SNPM':
      return 'Snapmint'
    case 'SAVED_MODES':
      return 'Saved mode'
    case 'PAY_LATER':
      return 'Pay later'
    case 'NETBANKING':
      return 'Netbanking'
    case 'FREE':
      return 'Online payment'
    case 'EMI':
      return 'EMI'
    case 'CASHFREE':
      return 'Cashfree'
    case 'EASEBUZZ':
      return 'Easebuzz'
    case 'CARD':
      return 'Card'
    case 'PL':
      return 'Online payment'
    case 'GC':
      return 'Gift card'
    case 'BHARATX':
      return 'BharatX'
    case 'COD':
      return 'Cash on delivery'
    default:
      return 'Online payment'
  }
}

export const orderTableDrawerAdapter = (data: any) => {
  const checkoutInfo = data?.checkout_information
  const fmsOrders = data?.fms_orders
  const refundInfo = data?.refund_information
  const settlementInfo = data?.settlement_information
  const platformOrder = data?.platform_orders
  const customer = platformOrder?.data?.customer
  const orderInfo = data?.order_information
  const lineItems = data?.line_items

  const successfulTransaction: any = {}
  const transactionHistory: any[] = []
  fmsOrders?.forEach((item: any, index: number) => {
    const transactionAmount = `\u20B9${numberWithCommas(item?.amount)}`
    item?.transactions?.forEach((transaction: any, transactionIndex: number) => {
      const isCODTransaction = transaction?.payment_mode === 'COD'

      if (transaction?.status === 'COMPLETED' && !isCODTransaction) {
        successfulTransaction.status = transaction?.Status
        successfulTransaction.payment_mode = mapPaymentMode(transaction?.payment_mode)
      }

      if (transaction?.status === 'FAILED') failedTransactionCount++
      const amountStr =
        transaction?.status === 'FAILED' || transaction?.status === 'CANCELLED' || isCODTransaction
          ? 'Amount payable'
          : 'Amount paid'
      transactionHistory?.push([
        {
          label: amountStr,
          value: transactionAmount
        },
        { label: 'Mode', value: mapPaymentMode(transaction?.payment_mode) ?? orderTableConstants.DASH },
        {
          label: 'Date',
          value: isCODTransaction
            ? orderTableConstants.DASH
            : `${getDateTime(new Date(transaction?.created_at))?.date} at ${
                getDateTime(new Date(transaction?.created_at))?.time
              }`
        },
        { label: 'Status', value: transaction?.status ?? orderTableConstants.DASH },
        { label: 'Gateway', value: mapGateWay(transaction?.pg_type) },
        { label: 'PG payment ID', value: transaction?.pg_payment_id ?? orderTableConstants.DASH }
      ])
    })
  })

  const refundTransaction = fmsOrders?.find((transactionData: any) => transactionData?.status === 'REFUNDED')
  const partialRefundTransaction = fmsOrders?.find(
    (transactionData: any) => transactionData?.status === 'PARTIALLY_REFUNDED'
  )
  const cancelledTransaction = fmsOrders?.find((transactionData: any) => transactionData?.status === 'CANCELLED')
  // TODO remove metadata removal and item name population from banckend

  let failedTransactionCount = 0

  const varaintDetails = lineItems?.map((item: any, index: number) => {
    return {
      itemID: item?.id,
      itemName: item?.product_title ?? '',
      itemPrice: `${(parseFloat(item?.price_after_discount) / 100).toString()}`,
      ...(Boolean(item?.price_after_discount !== item?.price)
        ? { strikedPrice: `${(parseFloat(item?.price) / 100).toString()}` }
        : {}),
      itemQuantity: item?.quantity,
      imageURL: item?.image,
      itemVariantName: item?.variant_title ?? '',
      productId: item?.product_id,
      originalQty: item?.quantity,
      isIncreasable: true,
      isDecreasable: true,
      showRestockCheckbox: false,
      isDiscountApplied: Boolean(item?.total_discount > 0),
      restock: true,
      discount: { type: 'PERCENTAGE', amount: '' }
    }
  })

  const getCODAmount = () => {
    const shippingCharge = checkoutInfo?.pricing?.shipping
    const codCharge = checkoutInfo?.pricing?.cod
    const totalCharge = shippingCharge + codCharge
    return `\u20B9${numberWithCommas(
      orderInfo?.order_type === 'COD' ? (totalCharge > 0 ? totalCharge : 0) : shippingCharge > 0 ? shippingCharge : 0
    )}`
  }

  const parsedShippingData = [
    {
      amount: getCODAmount() ?? orderTableConstants.DASH,
      label: checkoutInfo?.metadata?.shipping_lines?.[0]?.presentment_title
    }
  ]

  const parsedRewardsData = checkoutInfo?.rewards?.map((item: any, index: number) => {
    return {
      type: item?.type,
      points: `${item?.metadata?.points}`,
      amount: `\u20B9${numberWithCommas(item?.amount)}`
    }
  })

  const parsedSubtotalData = [
    {
      items: lineItems?.length,
      amount: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.sub_total)}`
    }
  ]

  const parsedTaxData = [
    {
      amount: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.tax)}`,
      included: checkoutInfo?.pricing?.taxes_included
    }
  ]

  const parsedDiscountData = checkoutInfo?.discounts
    ?.filter((item: any) => item?.discount_code !== 'CHECKOUT_REWARDS')
    ?.map((item: any, index: number) => {
      return {
        code: item?.coupon_metadata?.title ?? item?.discount_code,
        amount: `- \u20B9${numberWithCommas(item?.discount)}`,
        label: item?.source
      }
    })

  const parsedSettlements = settlementInfo?.map((item: any, index: number) => {
    return [
      {
        label: 'Date',
        value: Boolean(item?.metadata)
          ? typeof item?.metadata?.settled_at === 'number'
            ? `${getDateTime(new Date(item?.metadata?.settled_at * 1000))?.date} at ${
                getDateTime(new Date(item?.metadata?.settled_at * 1000))?.time
              }`
            : `${getDateTime(new Date(item?.metadata?.settled_at))?.date} at ${
                getDateTime(new Date(item?.metadata?.settled_at))?.time
              }`
          : orderTableConstants.NOT_APPLICABLE
      },
      { label: 'Status', value: Boolean(item?.pg_reference_id) ? 'Settled' : orderTableConstants.NOT_APPLICABLE },
      {
        label: 'UTR',
        value: Boolean(item?.bank_reference_id) ? item?.bank_reference_id : orderTableConstants.NOT_APPLICABLE
      },
      {
        label: item?.context === 'REFUND' ? 'Refund ID' : 'Settlement ID',
        value: Boolean(item?.pg_reference_id) ? item?.pg_reference_id : orderTableConstants.NOT_APPLICABLE
      },
      { label: 'Settlement type', value: Boolean(item?.context) ? item?.context : orderTableConstants.NOT_APPLICABLE }
    ]
  })

  const parsedRefunds = refundInfo?.map((item: any, index: number) => {
    return [
      {
        label: 'Date',
        value: `${getDateTime(new Date(item?.created_at))?.date} at ${getDateTime(new Date(item?.created_at))?.time}`
      },
      { label: 'Refund amount', value: `\u20B9${item?.amount}` },
      { label: 'Refund ID', value: item?.pg_reference_id },
      { label: 'Refund type', value: item?.refund_type },
      { label: 'Status', value: item?.status === 'COMPLETED' ? 'Completed' : item?.status }
    ]
  })

  const getAddress = (address: any) => {
    return [
      {
        name: `${address?.name ?? ''}`,
        addressLine1: `${address?.address1} ${address?.address2}`,
        addressLine2: `${address?.city} - ${address?.zip}`,
        email: `${address?.email}`,
        phone: `${address?.phone ?? ''}`
      }
    ]
  }

  const getBillingAddress = (address: any) => {
    return [
      {
        name: `${address?.name ?? ''}`,
        addressLine1: `${address?.address1} ${address?.address2}`,
        addressLine2: `${address?.city} - ${address?.zip}`,
        email: `${address?.email}`,
        phone: `${address?.phone}`
      }
    ]
  }

  const getPaymentStatus = () => {
    const status =
      successfulTransaction?.status ??
      refundTransaction?.status ??
      partialRefundTransaction?.status ??
      cancelledTransaction?.status
    switch (status) {
      case 'PARTIALLY_REFUNDED':
        return 'Partially refunded'
      case 'REFUNDED':
        return 'Refunded'
      case 'COMPLETED':
        return 'Completed'
      case 'CANCELLED':
        return 'Cancelled'
      default:
        return orderTableConstants.NOT_APPLICABLE
    }
  }

  const getUTMDetails = (data: any) => {
    const utmDataArray = data
      ?.filter((filterData: any) => filterData?.name !== 'GSTIN' || filterData?.name !== 'GSTINBusinessName')
      ?.map((utmData: any) => {
        return {
          label: utmData?.name,
          value: Boolean(utmData?.value?.length) ? utmData?.value : orderTableConstants.DASH
        }
      })
    return utmDataArray
  }

  const getGSTIN = (data: any) => {
    return data?.find((item: any) => item?.name === 'CustomerGSTIN')?.value ?? orderTableConstants.DASH
  }
  const getBusinessName = (data: any) => {
    return data?.find((item: any) => item?.name === 'GSTINBusinessName')?.value ?? orderTableConstants.DASH
  }

  return {
    status: checkoutInfo?.status ?? orderTableConstants.DASH,
    orderId: platformOrder?.data?.name ?? orderTableConstants.DASH,
    dateCreated: `${getDateTime(new Date(checkoutInfo?.created_at))?.date} at ${
      getDateTime(new Date(checkoutInfo?.created_at))?.time
    }`,
    customerName: `${data?.customer_details?.name ?? ''}`,
    email: data?.customer_details?.email ?? orderTableConstants.DASH,
    phone: data?.customer_details?.phone ?? orderTableConstants.DASH,
    address: getAddress(data?.addresses?.shipping_address),
    paymentMode:
      orderInfo?.order_type === 'COD'
        ? 'COD'
        : orderInfo?.order_type === 'SPLIT_COD'
        ? 'SPLIT COD'
        : successfulTransaction?.payment_mode,
    subtotal: parsedSubtotalData?.length > 0 ? parsedSubtotalData : orderTableConstants.DASH,
    totalAmountPaid: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.total_payable)}`,
    totalPrice: `\u20B9${numberWithCommas(orderInfo?.amount)}`,
    ...(orderInfo?.order_type === 'COD' ? { cod: `\u20B9${numberWithCommas(checkoutInfo?.pricing?.cod)}` } : {}),
    taxes: parsedTaxData?.length > 0 ? parsedTaxData : orderTableConstants.DASH,
    shipping: parsedShippingData ?? orderTableConstants.DASH,
    orderStatus: orderInfo?.status,
    paymentStatus:
      orderInfo?.order_type === 'COD'
        ? orderTableConstants.NOT_APPLICABLE
        : getPaymentStatus() ?? orderTableConstants.NOT_APPLICABLE,
    refunds: parsedRefunds ?? orderTableConstants.NOT_APPLICABLE,
    variantDetails: varaintDetails ?? orderTableConstants.NOT_APPLICABLE,
    transactionHistory: transactionHistory?.length ? transactionHistory : orderTableConstants.DASH,
    failedTransactionCount: failedTransactionCount.toString(),
    settlements: parsedSettlements ?? orderTableConstants.NOT_APPLICABLE,
    rewards: parsedRewardsData?.length > 0 ? parsedRewardsData : orderTableConstants.DASH,
    discounts: parsedDiscountData?.length > 0 ? parsedDiscountData : orderTableConstants.DASH,
    outStandingAmount: platformOrder?.data?.total_outstanding,
    utmDetails: getUTMDetails(checkoutInfo?.metadata?.note_attributes),
    GSTIN: getGSTIN(platformOrder?.data?.note_attributes),
    businessName: getBusinessName(platformOrder?.data?.note_attributes),
    billingAddress: getBillingAddress(data?.platform_orders?.data?.billing_address)
  }
}

export const localDeliveryGroupAdapter = (data: any) => {
  return {
    groupID: data?.group_id,
    groupName: data?.name,
    etdText: data?.etd_text,
    isActive: data?.enabled,
    charges:
      data?.delivery_pricing_conditions?.length > 1
        ? 'Conditional'
        : data?.delivery_pricing_conditions?.[0]?.delivery_price ?? 0
  }
}

export const salesByTableAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['salesBy'] = columnValue
      case 1:
        resultingRow['revenue'] = roundToDecimals(columnValue)
      case 2:
        resultingRow['ordersCount'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const funnelAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['eventName'] = columnValue
      case 1:
        resultingRow['sessions'] = columnValue
      case 2:
        resultingRow['percentage'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const conversionReportAdapter = (data: any) => {
  const resultingRow: any = { ...data }
  return resultingRow
}

export const discountFailureRateAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['code'] = columnValue
      case 1:
        resultingRow['discounts_selected'] = columnValue
      case 2:
        resultingRow['codes_entered'] = columnValue
      case 3:
        resultingRow['failed_attempts'] = columnValue
      case 4:
        resultingRow['failure_rate'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const discountConversionsAdapter = (data: any) => {
  const resultingRow: any = {}
  data?.forEach((columnValue: any, index: number) => {
    switch (index) {
      case 0:
        resultingRow['code'] = columnValue
      case 1:
        resultingRow['checkouts'] = columnValue
      case 2:
        resultingRow['orders'] = columnValue
      case 3:
        resultingRow['conversions'] = columnValue
    }
  })
  resultingRow['id'] = Math.random()

  return resultingRow
}

export const customerTransactionDataAdapter = (data: any) => {
  const parsedData = data?.map((row: any) => {
    const { date, time } = getDateTime(row?.checkout_details?.created_at)
    return {
      id: row?.transaction_id,
      createdAt: `${getDateTime(row?.created_on)?.date} at ${getDateTime(row?.created_on)?.time}`,
      status: row?.status,
      transactionAmount: `\u20B9 ${numberWithCommas(row?.transaction_amount)}`,
      transactionType: row?.transaction_type,
      transactionId: row?.transaction_id,
      userId: row?.user_id,
      walletId: row?.wallet_id,
      reason: row?.reason ?? '',
      reference: row?.reference ?? '',
      checkoutDetails: {
        orderType: row?.checkout_details?.order_type,
        platform: row?.checkout_details?.platform,
        orderId: row?.checkout_details?.platform_order_id,
        checkoutId: row?.checkout_details?.checkout_id,
        orderAmount: `\u20B9 ${numberWithCommas(row?.checkout_details?.amount)}`,
        orderCreatedAt: Boolean(date) && Boolean(time) ? `${date} at ${time}` : orderTableConstants.NOT_APPLICABLE
      }
    }
  })
  return parsedData
}

//need to update the adapter
export const paymentSplitReportAdapter = (data: any) => {
  const resultingRow = data
  resultingRow['id'] = Math.random()
  return resultingRow
}

export const channelListAdapter = (data: any) => {
  const resultingRow = {
    channelName: data?.name,
    id: data?.id
  }
  return resultingRow
}

export const upSellAdapter = (row: any): UpSellTableDataType => {
  const parseUpsellProducts: UpsellEntitledProductType[] = row?.entitled_products?.map(
    (entitled_product: any, index: number) => {
      return {
        productId: entitled_product?.product_id,
        priority: entitled_product?.priority,
        productDiscount: {
          concessionAmount: entitled_product?.discount?.concession_amount,
          type: entitled_product?.discount?.type
        }
      }
    }
  )

  return {
    id: row?.id,
    name: row?.name,
    entitledProductIds: row?.entitled_product_ids,
    entitledProducts: parseUpsellProducts,
    prerequisiteProductIds: row?.prerequisite_product_ids,
    prerequisiteVariantIds: row?.prerequisite_variant_ids,
    prerequisiteCollectionIds: row?.prerequisite_collection_ids,
    viewCount: row?.view_count,
    appliedCount: row?.applied_count,
    gmvProcessed: row?.gmv_processed,
    isActive: row?.is_active,
    createdAt: row?.created_at,
    updatedAt: row?.updated_at,
    activeFrom: row?.active_from,
    activeUntil: row?.active_until,
    metadata: row?.metadata
  }
}
