import { UserType, OrganisationType, PermissionType, MonthlyOrderValueType, RoleValueType } from 'src/lib/types/user'

export const getParsedUserObj = (data: any) => {
  const organisations: OrganisationType[] = []
  data?.organizations?.forEach((organisation: any) => {
    const value: OrganisationType = {
      merchantId: organisation.merchant_id,
      name: organisation.organization_name,
      isLoggedIn: organisation.logged_in,
      isOwner: organisation.owner,
      isInvited: organisation.invited,
      isOnboarding: Boolean(organisation.in_onboarding),
      shopDomain: organisation.shop_domain
    }
    organisations.push(value)
  })
  const permissions: PermissionType[] = []
  data?.permissions?.forEach((permission: any) => {
    const value: PermissionType = {
      name: permission.display_name,
      id: permission.name,
      description: permission.description,
      parentPermissionId: permission.parent_permission_id,
      totalChildPermissions: permission.total_child_permissions ?? 0,
      metadata: permission.metadata
    }
    permissions.push(value)
  })
  const parsedData: UserType = {
    firstName: data?.first_name,
    lastName: data?.last_name,
    id: data?.id,
    email: data?.oid,
    isAdmin: data?.is_admin,
    organisations: organisations,
    permissions: permissions,
    role: data?.company_role,
    phoneNumber: data?.phone_number,
    isSuperAdmin: data?.id === 'super-admin' ? true : false,
    roles: data?.roles.map((role: any) => role.name),
    isDemoUser: Boolean(data?.demo_user)
  }
  return parsedData
}

export const monthlyOrderValues: MonthlyOrderValueType[] = [
  { value: 'LESS_THAN_1_LAKH', label: 'Less than 1 lac' },
  { value: 'BETWEEN_1_AND_5_LAKH', label: 'Between 1 lac and 5 lacs' },
  { value: 'BETWEEN_5_AND_10_LAKH', label: 'Between 5 lacs and 10 lacs' },
  { value: 'BETWEEN_10_AND_20_LAKH', label: 'Between 10 lacs and 20 lacs' },
  { value: 'BETWEEN_20_AND_50_LAKH', label: 'Between 20 lacs and 50 lacs' },
  { value: 'BETWEEN_50_AND_70_LAKH', label: 'Between 50 lacs and 70 lacs' },
  { value: 'MORE_THAN_70_LAKH', label: 'More than 70 lacs' }
]

export const roleValues: RoleValueType[] = [
  { label: 'Founder', value: 'Founder' },
  { label: 'Growth', value: 'Growth' },
  { label: 'Marketer', value: 'Marketer' },
  { label: 'Support', value: 'Support' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Agency Partner', value: 'Agency Partner' },
  { label: 'Product', value: 'Product' },
  { label: 'Others', value: 'Others' }
]
